//Homepage slider
.home-slides .home-slide {

  img {
    width: 100%; }

  .slider-img-desktop {
    display: block;

    @include mq(1200) {
      display: none; } }

  .slider-img-tablet {
    display: none;

    @include mq(1200) {
      display: block; }

    @include mq(tablet-small) {
      display: none; } }

  .slider-img-mobile {
    display: none;

    @include mq(tablet-small) {
      display: block; }

    @include mq(phone) {
      display: none; } }

  .slider-img-mobile-portrait {
    display: none;

    @include mq(phone) {
      display: block; } } }


//How it works
.blocks-holder {
  position: relative;
  width: 1060px;
  margin: 0 auto 240px;
  @include mq(1200) {
    width: 100%;
    margin-bottom: 150px; }

  @include mq(phone-big) {
    margin-bottom: 60px; }

  @include mq(phone) {
    margin-bottom: 40px; }

  .hiw-arrow {
    position: absolute;
    width: 290px;

    @include mq(tablet-small) {
      display: none; } }

  .arrow1 {
    top: 270px;
    left: 250px;

    @include mq(tablet) {
      left: 50px; } }

  .arrow2 {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    top: 520px;
    right: 270px;

    @include mq(tablet) {
      top: 440px;
      right: 60px; } }

  .arrow3 {
    top: 810px;
    left: 250px;

    @include mq(tablet) {
      left: 150px; } }

  .hiw-pic {
    position: absolute;

    @include mq(tablet-small) {
      width: 100px; }

    @include mq(phone-big) {
      display: none; }

    @include mq(phone-small) {
      width: 80px; } }

  .hiw-pic1 {
    top: 135px;
    left: 290px;
    @include transform(rotate(-15deg));

    @include mq(tablet) {
      top: 215px;
      left: 150px; }

    @include mq(tablet-small) {
      top: 40px;
      right: 0;
      left: initial; } }

  .hiw-pic2 {
    top: 350px;
    right: 310px;
    @include mq(1200) {
      top: 370px;
      right: 210px; }
    @include mq(tablet) {
      right: 160px; }

    @include mq(tablet-small) {
      top: 310px;
      right: 0; } }

  .hiw-pic3 {
    top: 710px;
    left: 310px;
    @include mq(1200) {
      top: 730px;
      left: 240px; }
    @include mq(tablet) {
      top: 740px;
      left: -50px; }
    @include mq(tablet-small) {
      top: 610px;
      left: initial;
      right: 0; } }

  .hiw-pic4 {
    top: 930px;
    right: 300px;
    @include transform(rotate(15deg));

    @include mq(1200) {
      top: 970px;
      right: 230px; }

    @include mq(tablet) {
      top: 980px;
      right: 10px; }

    @include mq(tablet-small) {
      top: 870px;
      right: 0; }

    @include mq(phone-big) {
      top: 820px; } } }

.hiw-block-holder {
  @include clearfix;
  width: 100%;

  @include mq(tablet-small) {
    margin-bottom: 50px; }

  @include mq(phone-big) {
    margin-bottom: 20px; }

  @include mq(phone) {
    margin-bottom: 15px; }

  &.step1 .hiw-block {
    width: 320px;
    float: left;

    @include mq(tablet-small) {
      width: 100%; } }

  &.step2 .hiw-block {
    width: 350px;
    float: right;
    margin-top: -120px;
    margin-bottom: 140px;

    @include mq(tablet-small) {
      width: 100%;
      margin: 0; } }

  &.step3 .hiw-block {
    width: 330px;
    float: left;
    margin-bottom: 50px;

    @include mq(tablet-small) {
      width: 100%;
      margin: 0; } }

  &.step4 .hiw-block {
    width: 320px;
    float: right;

    @include mq(tablet-small) {
      width: 100%;
      margin: 0; } } }


.hiw-block {
  position: relative;

  p {
    font: {
      family: $font-bold;
      size: 28px; }
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 35px;

    @include mq(tablet-small) {
      margin-bottom: 25px;
      font-size: 25px; }

    @include mq(phone-big) {
      text-align: left; } }

  h4 {
    font: {
      family: $font-medium;
      size: 24px; }
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include mq(tablet-small) {
      margin-bottom: 25px;
      font-size: 22px; }

    @include mq(phone-big) {
      text-align: left; } }

  span {
    font-size: 20px;
    letter-spacing: 0.025em;
    line-height: 1.2;

    @include mq(tablet-small) {
      text-align: center;
      display: block;
      font-size: 19px; }

    @include mq(phone-big) {
      font-size: 17px;
      text-align: left; }

    @include mq(phone-small) {
      font-size: 16px; } } }

.hiw-examples {

  img {
    margin: 0 auto 130px;
    max-width: 100%;

    @include mq(1200) {
      margin-bottom: 90px; } } }

.hiw-small-pic {
  display: none;

  @include mq(phone-big) {
    display: block;
    max-width: 130px; } }





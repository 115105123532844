// Title
.inner-page-wrap {
  padding-top: 75px;

  @include mq(tablet) {
    padding-top: 60px; }

  h2 {
    @include inner-page-title();
    @include mq(1200) {
      font-size: 32px; }

    @include mq(tablet) {
      font-size: 28px;
      margin-bottom: 50px; } }

  h3 {
    @include inner-page-subtitle();

    @include mq(1200) {
      font-size: 26px; }

    @include mq(tablet) {
      font-size: 24px; } }


  &.galleries-page {
    padding-top: 40px;
    margin-bottom: 80px; } }


// Currency & Units select
.currency-units-select {
  @include order-section-setting();

  .select-holder {
    @extend .col-md-6;

    font: {
      family: $font-mp-semiboldIT;
      size: 23px; }

    &:first-of-type {
      @include mq(tablet) {
        margin-bottom: 40px; } }

    p {
      @include basic-text();
      text-align: center;
      margin-bottom: 15px; }

    .select2-container--default .select2-selection--single {
      border: 1px solid #000;
      border-radius: 0;
      color: #000;
      height: 34px; }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #000; }

    .select2-container .select2-selection--single .select2-selection__rendered {
      height: 34px;
      line-height: 34px; }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 34px; }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #000 transparent transparent transparent; }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #000 transparent; }

    .select2 {
      width: 240px !important;
      display: block;
      height: 34px;
      margin: 0 auto;
      text-align: center; } } }


// Medium select
.medium-select-wrap {
  @include order-section-setting(); }

.medium-select-holder {
  text-align: center;
  padding-bottom: 40px;

  .medium-select-single {
    display: inline-block;
    margin: 0 20px;

    @include mq(1200) {
      margin: 0 15px; }

    .medium-select {
      position: relative;
      width: 165px;
      height: 140px;
      border-radius: 14px;
      background: transparent;
      margin-bottom: 25px;
      cursor: pointer;

      .medium-picture {
        border: 1px solid #000;
        border-radius: 1px;
        width: auto;
        height: auto;
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        background: #fff;

        img {
          width: 145px;
          height: 112px; } } }

    p {
      @include basic-text;
      font-size: 18px;
      margin-bottom: 5px;
      text-transform: capitalize; }

    span {
      font: {
        style: italic; }
      letter-spacing: 0.1em;
      b {
        color: $site-red;
        font-weight: normal; } }

    &.active .medium-select {
      background: $site-blue; } } }

.medium-text-holder {
  max-width: 1140px;
  margin: 0 auto;
  border-top: 1px solid #7f7f7f;

  .medium-text-single {
    padding: 30px 90px;

    p {
      font: {
        family: $font-light;
        size: 16px; }
      letter-spacing: 0.1em;
      line-height: 19px;

      b {
        font-family: $font-medium; } } } }


// Type select
.type-select-wrap {
  @include order-section-setting();

  > p {
    font: {
      family: $font-regular;
      size: 16px; }
    text-align: center;
    margin-bottom: 45px; } }

.type-select-holder {
  text-align: center;

  .type-select-single-wrap {
    position: relative;
    display: inline-block;
    margin: 0 15px;

    > p {
      font: {
        family: $font-medium;
        size: 18px; }
      letter-spacing: 0.1em; }

    .type-select-outer {
      border-radius: 5px;
      border: 1px solid #000;
      background: #fff;
      padding: 14px 17px;
      //height: 130px
      margin-bottom: 34px;
      cursor: pointer; }

    &.people-type .type-select-outer {
 }      //width: 180px

    &.people-type:nth-child(3) .type-select-outer {
      //width: 300px

      .type-select-inner {
        width: 245px; } }

    &.people-type:nth-child(4) .type-select-outer {
      //width: 420px

      .type-select-inner {
        width: 365px; } }


    &.combined-type {

      img {
        @include position(absolute, 16px null null 28px);
        z-index: 1;
        width: 274px;
        height: 98px; } }


    .type-select-outer {
        position: relative;
        @include clearfix;

        .type-select-inner {
          float: left; }

        p {
          float: left;
          font: {
            family: $font-medium;
            size: 44px; }
          margin-top: 26px;
          visibility: hidden; } }

    &.without-type {

      img {
        @include position(absolute, 16px null null 28px);
        z-index: 1;
        width: 420px; }

      .type-select-outer {
        @include clearfix;
        width: 480px;

        .type-select-inner {
          float: left; }

        p {
          float: left;
          font: {
            family: $font-mp-semiboldIT;
            size: 24px; }
          margin: 37px 2px;
          visibility: hidden; } } }

    &.active .type-select-outer {
      background: $site-blue; }

    .type-select-inner {
      width: 125px;
      height: 100px;
      border: 1px solid #000;
      border-radius: 1px;
      background: #fff;

      img {
        width: 100%;
        height: 100%; } } } }

// Number select
.number-select-holder {
  @include order-section-setting;
  text-align: center;

  .single-number {
    display: inline-block;
    width: 45px;
    height: 45px;
    border: 1px solid #000;
    background: #fff;
    position: relative;
    margin: 0 16px;
    cursor: pointer;

    p {
      color: #000;
      font: {
        family: $font-regular;
        size: 28px; }
      @include position(absolute, 50% null null 50%);
      @include transform(translate(-50%,-50%)); }

    &.active {
      background: $site-blue;

      p {
        color: #fff; } } } }

// Vlada (kopija .size-select.wrap > .size-select-about)
.Total {
  width: 350px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 60px;

  @include mq(phone-big) {
    width: 100%;
    margin-bottom: 20px; }

  .Total__Section {
    @include clearfix;
    margin-bottom: 7px;

    @include mq(phone-big) {
      margin-bottom: 10px; }

    p {
      float: left;
      font: {
        family: $font-regular;
        size: 16px; }
      letter-spacing: 0.1em;

      @include mq(phone-big) {
        font-size: 14px;
        float: none;
        width: 100%; } }

    span {
      float: right;
      font: {
        family: $font-bold;
        size: 16px; }
      letter-spacing: 0.1em;

      @include mq(phone-big) {
        float: none; }

      span {
        float: left !important; }

      span:last-of-type {
        float: right !important;

        @include mq(phone-big) {
          float: left !important; } } } } }

// Size select
.size-select-wrap {
  @include order-section-setting;

  > p {
    font: {
      family: $font-regular;
      size: 16px; }
    text-align: center;
    margin-bottom: 45px; }

  .size-select-holder {
    text-align: center;
    max-width: 730px;
    margin: 0 auto;

    .single-size {
      display: inline-block;
      width: 125px;
      height: 45px;
      border: 1px solid #000;
      background: #fff;
      position: relative;
      margin: 0 16px 25px;
      cursor: pointer;

      p {
        color: #000;
        font: {
          family: $font-regular;
          size: 24px; }
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%)); }

      &.active {
        background: $site-blue;

        p {
          color: #fff; } } } }

  .size-select-about {
    width: 350px;
    margin: 0 auto;
    margin-top: 20px;

    .size-about-line {
      @include clearfix;
      margin-bottom: 7px;

      p {
        float: left;
        font: {
          family: $font-regular;
          size: 16px; }
        letter-spacing: 0.1em; }

      span {
        float: right;
        font: {
          family: $font-bold;
          size: 16px; }
        letter-spacing: 0.1em; } } } }

// Frame select
.frame-select-wrap {
  @include order-section-setting;

  padding: 20px 173px;
  margin: 0 auto 15px;

  h2 {
    margin-bottom: 80px !important; }

  @include mq(desktop) {
    padding: 20px 80px; }

  @include mq(1200) {
    width: 100%; }

  > p {
    font: {
      size: 24px; }
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 55px; } }

.frame-select-holder {
  @include clearfix;

  .frame-half {
    @extend .col-md-6;

    &:first-of-type {
      @include mq(1200) {
        padding: 0; } }

    .single-frame {
      display: inline-block;
      margin: 12px 0;
      position: relative;
      width: 145px;
      height: 145px;
      background: transparent;
      cursor: pointer;

      &.active {
        background: $site-blue;
        border-radius: 15px; }

      .frame-inner {
        width: 122px;
        height: 122px;
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        border: 1px solid #000;
        background: #fff;

        .frame-price {
          margin-top: 15px;
          font-size: 19px;
          text-align: center; }

        p {
          font: {
            size: 18px; }
          letter-spacing: 0.1em;
          text-align: center;
          @include position(absolute, 50% null null 50%);
          @include transform(translate(-50%,-50%)); } } }

    .frame-preview {
      position: relative;
      width: 380px;
      height: 440px;
      margin: 200px auto 0;

      img {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        max-width: 100%; }

      .frame-name {
        @include position(absolute,-19% null null 50%);
        @include transform(translate(-50%,-50%));
        font-size: 24px; }

      .frame-price {
        @include position(absolute,-12% null null 50%);
        @include transform(translate(-50%,-50%));
        font-size: 21px;

        .value {
          float: right; } } } }

  .frame-select-about {
    width: 350px;
    margin: 0 auto;
    margin-top: 20px;

    .frame-about-line {
      @include clearfix;
      margin-bottom: 7px;

      p {
        float: left;
        font: {
          family: $font-regular;
          size: 16px; }
        letter-spacing: 0.1em; }

      span {
        float: right;
        font: {
          family: $font-bold;
          size: 16px; }
        letter-spacing: 0.1em; } } } }

// Orientation select
.orientation-select-wrap {
  @include order-section-setting;

  > p {
    font: {
      size: 16px; }
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 30px; } }

.orientation-select-holder {
  text-align: center;

  .orientation-single-holder {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &.active .orientation-select-single {
      background: $site-blue; }

    p {
      font: {
        family: $font-medium;
        size: 16px; }
      letter-spacing: 0.1em; }

    &:nth-child(2) {

      .orientation-select-single {
        width: 190px;
        height: 150px;

        .orientation-select-inner {
          width: 170px;
          height: 125px; } }

      p {
        margin-top: 55px; } } }

  .orientation-select-single {
    position: relative;
    background: transparent;
    vertical-align: middle;
    margin: 0 70px;
    border-radius: 13px;

    .orientation-select-inner {
      @include position(absolute, 50% null nul 50%);
      @include transform(translate(-50%,-50%));
      border: 7px solid #000;
      background: #fff; }

    &.active {
      background: $site-blue; }

    &.portrait {
      width: 150px;
      height: 190px;
      margin-bottom: 15px;

      .orientation-select-inner {
        width: 125px;
        height: 170px; } }

    &.landscape {
      width: 190px;
      height: 150px;

      .orientation-select-inner {
        width: 170px;
        height: 125px; } } } }

.orientation-artist-choose-holder {
  margin-top: 40px;
  margin-bottom: 50px;


  p {
    font: {
      family: $font-medium;
      size: 16px; }
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase; }

  span {
    display: block !important;
    margin: 0 auto !important; } }

.artist-sign-holder {
  //width: 25px
  //height: 25px
  //border: 1px solid #000
  //background: #fff
  //margin: 0 auto
  margin-bottom: 35px;

  input {
    display: block;
    margin: 0 auto; }

  p {
    font: {
      family: $font-medium;
      size: 29px; }
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 8px; }

  span {
    display: block !important;
    margin: 0 auto 30px !important; } }


// Background select
.background-select-wrap {
  @include order-section-setting;

  .artist-sign-holder {

    p {
      font: {
        size: 24px; }
      margin-bottom: 5px;
      text-align: center; }

    .artist-sign {
      width: 30px;
      height: 30px;
      border: 1px solid #000;
      background: #fff;
      margin: 0 auto 40px;
      display: block;

      &.active {

        background: $site-blue; } } } }

.choose-bg-wrap {
  ul {
    text-align: center;
    font-size: 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 45px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $site-blue; }

    li {
      position: relative;
      z-index: 8;
      margin-bottom: -1px;
      display: inline-block;
      padding: 25px 45px;
      border: 7px solid $site-blue;

      font: {
        size: 24px; }
      letter-spacing: 0.1em;
      background: #fff;
      color: #7F7F7F;
      cursor: pointer;
      @include transition(.2s linear);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      &:hover {
        background: #fff;
        text-decoration: underline; }

      &.active {
        border: 7px solid transparent;
        background: $site-blue;
        color: #fff;
        font-family: $font-medium;
        font-size: 24px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }

      &.active:before {
        font-family: 'FontAwesome';
        content: '\f00c';
        font-size: 20px;
        position: absolute;
        top: 8px;
        right: 12px;
        color: #fff; } } }

  //&:before
  //  //  content: ''
  //  //  display: block
  //  //  height: 1px
  //  //  width: 120px
  //  //  background: $site-blue
  //  //  +position(absolute, null null 0 50%)
  //  //  +transform(translate(-50%,0))
  //  //  +transition(.2s linear)
  //  //
  //  //&:hover:before
  //  //  width: 90%

  .choose-bg-holder {
    text-align: center;
    padding-top: 45px;

    label {
      font-size: 24px;
      display: block;
      margin-bottom: 30px; }

    textarea {
      display: block;
      width: 700px;
      height: 200px;
      border: 2px solid #000;
      margin: 0 auto;
      resize: none;
      padding: 15px;
      font: {
        family: $font-regular;
        size: 20px; }

      @include mq(1200) {
        width: 100%; } }

    .single-bg-holder {
      display: inline-block;
      margin-bottom: 15px;

      .single-bg-outer {
        width: 145px;
        height: 145px;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;

        .single-bg-inner {
          width: 122px;
          height: 122px;
          @include position(absolute, 50% null null 50%);
          @include transform(translate(-50%,-50%));
          border: 1px solid #000;
          background: #fff; } }

      p {
        font: {
          size: 22px; }
        color: #7F7F7F; }

      &.active .single-bg-outer {
        background: $site-blue;
        border-radius: 14px; }

      &.active p {
          font-family: $font-medium;
          color: #000; } } } }

// Comment section
.comment-section {
  @include order-section-setting;

  > p {
    font: {
      size: 16px; }
    text-align: center;
    line-height: 20px;
    margin-bottom: 45px; }

  textarea {
    display: block;
    width: 950px;
    height: 260px;
    border: 2px solid #000;
    margin: 0 auto;
    resize: none;
    padding: 15px;
    font: {
      family: $font-regular;
      size: 20px; }

    @include mq(1200) {
      width: 100%; } } }

// Upload picture section

.upload-photo-wrap {
  @include order-section-setting;

  h3 {
    @include mq(tablet-big) {
      font-size: 16px;
      float: none;
      width: 100%; } }

  @include mq(tablet-big) {
    background: #fff;
    padding-bottom: 40px; }

  label {
    display: block;
    width: 205px;
    height: 50px;
    border: none;
    background: $new-red;
    color: $new-yellow;
    border-radius: 3px;
    line-height: 50px;
    font: {
      family: $font-medium;
      size: 23px; }
    text-align: center;
    margin: 0 auto;
    cursor: pointer; }

  p {
    font-size: 22px;
    letter-spacing: 0.1em;
    margin-bottom: 30px;

    @include mq(tablet-big) {
      font-size: 19px;
      margin-bottom: 8px; } }


  .main-photo-upload {
    margin-bottom: 50px;

    p {
      text-align: center; }

    @include mq(tablet-big) {
      margin-bottom: 35px !important; } }

  .reference-photos {
    text-align: center;
    margin-bottom: 50px;

    .reference-photo {
      display: inline-block;
      margin: 0 100px;

      @include mq(desktop) {
        margin: 0 50px; }

      @include mq(tablet-big) {
        display: block;
        margin: 0 auto 35px; } } }

  .pic-upload-btn {
    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1; } }

  .big-or {
    position: relative;
    width: 950px;
    margin: 0 auto 40px;

    @include mq(tablet-big) {
      width: 100%; }

    &:after {
      content: '';
      @include position(absolute, null null 13px 0);
      width: 100%;
      height: 1px;
      background: #000;
      display: block; }

    p {
      font: {
        family: $font-medium;
        size: 28px; }
      width: 70px;
      margin: 0 auto;
      background: #fff;
      text-align: center;
      position: relative;
      z-index: 9; } }

  > span {
    font-size: 26px;
    display: block;
    text-align: center;
    letter-spacing: 0.1em;

    @include mq(tablet-big) {
      font-size: 22px; } } }

.continue-btn-holder {
  margin-bottom: 90px;

  .continue-btn {
    display: block;
    width: 700px;
    height: 60px;
    margin: 0 auto;
    background: $new-red;
    color: $new-yellow;
    border: none;
    border-radius: 3px;
    font-size: 34px;
    line-height: 58px;
    text-align: center;
    cursor: pointer;

    @include mq(tablet) {
      width: 100%; }

    @include mq(tablet-small) {
      font-size: 28px;
      height: 50px;
      line-height: 50px; }

    @include mq(phablet) {
      font-size: 23px; }

    @include mq(phone-big) {
      height: auto;
      line-height: 1.8;

      b {
        display: block; } }

    @include mq(phone-small) {
      font-size: 22px; }

    b {
      font-family: $font-medium; }

    i {
      font-style: normal;
      @include mq(tablet-big) {
        display: none; } } } }

.Uploader__Item.main-photo-upload {
  width: 205px;
  margin: 0 auto 50px; }

.form-group.comment-section {
  background: none;

  h3 {
    font-size: 16px;
    margin-bottom: 20px; }

  p br {
    display: none; }

  textarea {
    height: 200px;
    font-size: 19px;
    padding: 13px; } }

.form-group {

  .artist-sign-holder {
    margin-bottom: 0;

    p {
      font-size: 17px; } } }


.pricing-wrap {
  margin-bottom: 100px;

  .currency-units-select {
    margin-bottom: 80px;
    padding: 0 150px;

    @include mq(phablet) {
      padding: 0; }

    &:after {
      display: none; } } }

.premium-quality-text {
  font-family: $font-mp-semiboldIT;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px; }

.medium-holder {
  text-align: center;
  margin-bottom: 50px;

  .single-medium {
    display: inline-block;
    margin: 0 20px;

    @include mq(phablet) {
      display: block;
      margin-bottom: 10px; }

    a {
      display: block;
      position: relative;
      width: 150px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      border-bottom: 2px solid $site-blue;
      color: $site-blue;
      background: none;
      text-transform: capitalize;
      @include transition(all 0.08s ease-in);

      @include mq(1200) {
        width: 140px; }

      @include mq(phablet) {
        margin: 0 auto; }

      &:hover {
        color: #fff; }

      &:before {
        content: "";
        position: absolute;
        background: $site-blue;
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: -1;
        -webkit-transition: top 0.09s ease-in; }

      &:hover:before {
        top: 0; } }

    &.active {

      a {
        background: $site-blue;
        color: #fff; } } } }

.pricing-table-wrap {

  h5 {
    font: {
      family: $font-medium;
      size: 20px; }
    text-align: center;
    margin-bottom: 10px;
    white-space: nowrap; } }

.pricing-table {
  margin-bottom: 50px; }

.pricing-table-row {
  text-align: center;
  font-size: 0;

  &:hover .table-cell {
    background: #ABB7B7 !important; }

  &.head {

    .table-cell {
      background: $site-blue !important;
      border-right: 1px solid #fff;
      color: #fff;
      height: 40px;
      line-height: 40px;

      &:last-child {
        border-right-color: $site-blue; } } }

  .table-cell {
    width: 120px;
    display: inline-block;
    border-right: 1px solid $site-blue;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid $site-blue;
    background: #ececec;

    &:first-child {
      border-left: 1px solid $site-blue;
      background: #D2D7D3; } } }

.bellow-pricing-text {
  font-family: $font-black;
  font-size: 34px;
  color: $new-red;
  text-align: center; }

.pricing-table-wrap {

  >div {
    width: 1080px;
    margin: 0 auto;

    @include mq(1200) {
      width: 100%;
      overflow-x: scroll; }

    > div {
      width: 1080px;

      >div {
        @include mq(tablet-small) {
          float: none !important; } }


      >div:nth-of-type(1) {
        width: 1080px;
        float: left; }

      h5:nth-of-type(1) {
        text-align: left; }

      >div:nth-of-type(2) {
        width: 630px;
        float: left;

        .table-cell {
          width: 105px; } }

      h5:nth-of-type(2) {
        text-align: left; }

      >div:nth-of-type(3) {
        width: 430px;
        float: right;

        .table-cell {
          width: 215px; } }

      h5:nth-of-type(2) {
        text-align: left; } } } }



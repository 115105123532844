// Framing
.frames-wrap {
  text-align: center;
  margin-bottom: 100px;


  @include mq(tablet) {
    padding: 0; }

  .frames-holder {
    font-size: 0; }

  .single-frame {
    display: inline-block;
    margin: 12px 0;
    position: relative;
    width: 25%;
    min-height: 200px;
    background: #fff;
    vertical-align: top;

    @include mq(tablet) {
      width: 33.333%; }

    @include mq(tablet-small) {
      width: 50%; }

    @include mq(phone-big) {
      width: 95%;
      margin: 0 auto 30px; }

    &.active {
      background: $site-blue;
      border-radius: 15px; }

    .frame-inner {
      width: 85%;
      height: 285px;
      background: #fff;
      margin: 0 auto 10px;
      position: relative;
      overflow: hidden;

      @include mq(1200) {
        height: 225px; }

      @include mq(tablet-small) {
        width: 230px;
        height: 260px; }

      @include mq(phablet) {
        width: 180px;
        height: 220px; }

      img {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        max-width: 100%;
        height: 285px;

        @include mq(1200) {
          height: auto; } } }

    p.single-frame-name {
      font-family: $font-medium;
      font-size: 17px;
      letter-spacing: 0.1em;
      margin-bottom: 10px; }

    .frame-price {
      font-family: $font-regular;
      font-size: 21px;
      letter-spacing: 0.1em;
      color: $new-red;
      font-style: italic; } } }

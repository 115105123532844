//Contact page
.contact-form-content {
  padding-bottom: 110px;
  @include clearfix;

  @include mq(tablet) {
    padding-bottom: 70px; }

  .contact-form {
    @extend .col-md-7;
    padding-right: 50px;

    @include mq(tablet) {
      padding-right: 15px; }

    @include mq(phone-big) {
      padding: 0; } }

  .contact-sidebar {
    @extend .col-md-5; }

  form {

    .input-wrap {
      margin-bottom: 22px;

      @include mq(tablet) {
        margin-bottom: 17px; }

      label {
        font: {
          size: 24px; }
        padding-left: 12px;
        display: block;
        margin-bottom: 8px;
        letter-spacing: 0.1em;

        @include mq(tablet) {
          font-size: 20px;
          margin-bottom: 5px; }

        span {
          color: $site-red;
          font-family: $font-mp-semiboldIT;
          font-size: 30px;
          line-height: 0;
          vertical-align: middle; } }

      input {
        width: 100%;
        height: 56px;
        border: 2px solid #9c9c9c;
        padding: 0 20px;
        font-size: 22px;

        @include mq(tablet) {
          height: 40px;
          font-size: 20px;
          border: 0 15px; } }

      textarea {
        width: 100%;
        height: 280px;
        border: 2px solid #9c9c9c;
        padding: 20px;
        font-size: 22px;
        resize: none;

        @include mq(tablet) {
          height: 200px;
          padding: 15px; } } } }

  .photo-input-wrap {
    margin-bottom: 22px;
    position: relative;

    .Uploader__Item_bar {
      position: absolute;
      bottom: -2px;
      left: 0; }

    > span {
      font: {
        size: 24px; }
      padding-left: 12px;
      display: block;
      margin-bottom: 8px;
      letter-spacing: 0.1em;

      @include mq(tablet) {
        font-size: 20px;
        margin-bottom: 5px; }

      @include mq(phone-big) {
        font-size: 18px; } }


    .photo-upload-holder {
      @include clearfix;
      width: 100%;
      height: 56px;
      border: 2px solid #9c9c9c;

      @include mq(tablet) {
        height: 40px; }

      p {
        font: {
          size: 22px; }
        color: #999999;
        float: left;
        line-height: 56px;
        padding-left: 15px;
        width: 385px;
        white-space: nowrap;
        overflow: hidden;

        @include mq(tablet) {
          font-size: 20px;
          line-height: 40px; }

        @include mq(tablet-small) {
          width: 68%; }

        @include mq(phone-big) {
          font-size: 18px; } }

      label {
        float: right;
        width: 190px;
        height: 48px;
        background: $site-blue;
        color: #fff;
        font-size: 22px;
        text-align: center;
        line-height: 48px;
        margin: 2px 2px 0 0;
        cursor: pointer;

        @include mq(tablet) {
          height: 32px;
          font-size: 19px;
          line-height: 32px; }

        @include mq(tablet-small) {
          width: 31%;
          font-size: 18px; } }

      input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        cursor: pointer; } } }


  .submit-btn-holder {
    text-align: right;

    input {
      display: inline-block;
      width: 400px;
      height: 80px;
      border: none;
      color: $new-yellow;
      font: {
        family: $font-medium;
        size: 34px; }
      padding: 0;
      background: $new-red;
      border-radius: 3px;
      text-transform: uppercase;
      margin-top: 80px;
      @include transition(.2s ease);
      cursor: pointer;

      @include mq(tablet) {
        width: 290px;
        height: 60px;
        margin-top: 50px;
        font-size: 30px; }

      @include mq(phone-big) {
        width: 200px;
        height: 50px;
        margin-top: 40px;
        font-size: 24px; }

      &:hover {
        opacity: 0.8; } } }


  .contact-sidebar {

    @include mq(tablet) {
      padding: 0;
      margin-top: 40px; }

    img {
      max-width: 100%;
      display: block;
      margin-bottom: 40px;

      @include mq(tablet) {
        display: none; } }

    p {
      font: {
        size: 24px; }
      line-height: 28px;
      margin-bottom: 15px;

      @include mq(tablet-small) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px; } }

    h5 {
      font: {
        family: $font-medium;
        size: 28px; }
      margin-bottom: 10px; } } }

.thank-you-message {
  position: relative;
  font-family: $font-mp-italic;
  font-size: 48px;
  text-align: center;
  margin-bottom: 120px;

  @include mq(tablet) {
    font-size: 30px;
    margin-bottom: 60px; }

  @include mq(phone-big) {
    font-size: 27px;
    margin-bottom: 50px; }

  @include mq(phone) {
    font-size: 25px;
    margin-bottom: 40px; } }

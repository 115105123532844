//Fonts
@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Regular.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Medium';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Medium.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Black';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Black.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Bold.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-BoldItalic';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Bold Italic.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Light';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Light.otf'); }

@font-face {
  font-family: 'MinionPro-SemiboldIT';
  src: url('fonts/minioPro/MinionPro-SemiboldIt.otf'); }

@font-face {
  font-family: 'MinionPro-Italic';
  src: url('fonts/minioPro/MinionPro-It.otf'); }

//.desktop-menu
//
//  li
//    a
//      font:
//        family: 'Open-Sans', sans-serif
//        size: 16px

// Footer
footer {
  border-top: 1px solid #000;
  padding-top: 55px;
  background: #E7E7E7;


  .footer-content {
    @include clearfix;
    padding: 0 150px 50px;
    @include mq(desktop) {
      padding: 0 0 50px; }

    > div {
      @extend .col-md-3;

      a {
        display: block;
        font: {
          size: 18px; }
        margin-bottom: 5px;

        @include mq(tablet) {
          text-align: center;
          margin-bottom: 8px; } } } }

  .footer-copyright {
    padding: 30px 0 40px;
    border-top: 1px solid #7f7f7f;
    text-align: center;
    color: rgba(0,0,0,0.7);
    margin: 0 150px;
    font-size: 20px;

    @include mq(phablet) {
      margin: 0; } }

  .payment-pic {
    img {
      @include mq(tablet) {
        margin: 0 auto 10px; } } }

  .footer-pics-holder {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #7f7f7f;

    .central-pic {
      img {
        max-width: initial !important;
        height: 60px;

        @include mq(tablet) {
          width: auto !important; }

        @include mq(phone) {
          width: 100% !important;
          height: auto; } } }

    table {
      @include mq(tablet) {
        width: 100%; } }

    .footer-pic-holder {
      display: inline-block;
      vertical-align: top;
      margin: 0 100px;

      img {
        max-width: 170px;
        max-height: 100%;

        @include mq(tablet) {
          width: 170px;
          margin: 0 auto; } }

      @include mq(desktop) {
        margin: 0 40px; }

      @include mq(1200) {
        margin: 0 20px; }

      @include mq(tablet) {
        display: block;
        margin: 0 0 30px 0; }

      @include mq(phablet) {
        display: block; } } }

  .footer-nav {
    ul {
      text-align: center;

      li {
        display: inline-block;
        margin: 0 25px;
        color: rgba(0, 0, 0, 0.7);
        padding: 15px 0;
        font-size: 15px;


        @include mq(tablet) {
          margin: 0 10px;
          padding: 10px 0; }

        a:hover {
          text-decoration: underline; } } } } }

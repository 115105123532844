// Fonts
$font-regular: 'BrandonGrotesque-Regular', sans-serif;
$font-medium: 'BrandonGrotesque-Medium', sans-serif;
$font-black: 'BrandonGrotesque-Black', sans-serif;
$font-bold: 'BrandonGrotesque-Bold', sans-serif;
$font-boldIT: 'BrandonGrotesque-BoldItalic', sans-serif;
$font-light: 'BrandonGrotesque-Light', sans-serif;
$font-mp-italic: 'MinionPro-Italic', sans-serif;
$font-mp-semiboldIT: 'MinionPro-SemiboldIT', sans-serif;


// Colors
$site-red: #dc3c2f;
$site-blue: #294076;
$site-yellow: #fddd7f;
$new-red: #DD3D31;
$new-yellow: #FCF2D9;


// Font sizes
$base-font-size: 16px;
$title-normal-size: 48px;


// Sizes
$header-height: 82px;
$section-padding-bottom: 85px;

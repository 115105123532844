// select2 override
.select2-results {
  text-align: center; }

.select2-dropdown {
  border-color: #000; }

.select2-search--dropdown {
  display: none; }

.select2-container--open .select2-dropdown--below {
  border-radius: 0; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $site-blue; }


@import '1-base/fonts';
@import '0-plugins/plugins-dir';
@import '1-base/base-dir';
@import '2-modules/modules-dir';
@import '3-layouts/layouts-dir';
@import '4-pages/pages-dir';

.slick-loading {
	& .slick-list {
		background: none !important; } }

/* gallery */
.Gallery {
  &__Items {
    &_item {
      display: none;

      &.active {
        display: block; } } }
  &__Preview {
    background: none !important; }

  &__Nav {
    &_section {
      display: none;
      &.active {
        display: block; } } }

  &__Legend {
    margin: 0px;
    padding: 0px;
    margin-bottom: 5px;
    & li {
      width: 50%;
      display: inline-block;
      font-size: 20px;
      text-align: right;

      &:first-child {
        padding-right: 50px; }

      &:last-child {
        float: right;
        padding-left: 50px;
        text-align: left; } } } }

/* uploader */
.Uploader {
  &__Item {
    &_bar {
      width: 100%;
      display: none;
      margin-top: 10px;
      border-radius: 5px;
      position: relative;

      &.active {
        display: block; }

      & > span {
        position: absolute;
        left: 0;
        padding: 3px;
        min-width: 10%;
        color: #fff;
        background: #5cb85c;
        border-radius: 3px;
        text-align: center;
        font-size: 13px;
        font-weight: 700; } } } }

/* cart */
.Cart {
  &__Mobile {
    display: none;
    & .form-group {
      display: block;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;

      & label,
      & .select2-container {
        display: inline-block; }

      & label {
        width: 100%;
        margin-bottom: 5px; }
      & .select2-container {
        width: 100% !important;
        text-transform: capitalize; } }

    @include mq(tablet-big) {
      display: block; } }

  &__Desktop {
    @include mq(tablet-big) {
      display: none; } } }

.choose-bg-wrap {
  & .choose-bg-holder {
    display: none;

    &.active {
      display: block; } }

  textarea {
    width: 100%;
    resize: none; } }


/* bullshit */
.select2-container--open {
  text-transform: capitalize; }

.select2-search--dropdown {
  display: block; }

.alert {
  width: 100%;
  padding: 10px 20px;
  font-size: 20px;
  text-align: center;

  &-warning {
    color: #8a6d3b;
    background: #fcf8e3; } }

.Cart__Mobile {

  img {
    max-width: 400px;
    display: block;
    margin: 0 auto;

    @include mq(phone-big) {
      max-width: 100%; } } }

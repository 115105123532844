// Galleries
.galleries-page {
  @include mq(tablet-small) {
    display: none; } }


.gallery-slider-holder {
  margin-bottom: 50px; }

.samples-current {

  @include mq(tablet) {
    height: auto; }

  img {
    margin: 0 auto;
    max-width: 100%; } }

.gallery-tabs {

  ul {
    text-align: center;
    font-size: 0;
    border-bottom: 1px solid #000;
    position: relative;

    li {
      position: relative;
      z-index: 8;
      margin-bottom: -1px;
      display: inline-block;
      padding: 25px 45px;
      font: {
        size: 24px; }
      letter-spacing: 0.1em;
      color: #7F7F7F;
      cursor: pointer;
      border: 1px transparent;
      @include transition(all .2s);

      @include mq(tablet) {
        padding: 20px 40px;
        font-size: 22px; }

      &:hover {
        color: $site-blue; }

      &.active {
        border: 1px solid #000;
        border-bottom: #fff;
        background: #fff;
        color: $site-blue;
        font-family: $font-medium;
        font-size: 24px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        @include mq(tablet) {
          font-size: 22px; }

        &:before {
          content: '';
          display: block;
          height: 1px;
          width: 150px;
          background: $site-blue;
          @include position(absolute, null null 0 50%);
          @include transform(translate(-50%,0)); } } } } }

.choose-gallery-holder {
  padding: 40px 0 50px;

  @include mq(tablet-small) {
    padding: 30px 0 40px; } }

.choose-gallery-set {
  text-align: center;
  padding: 0 100px;
  font-size: 0;

  @include mq(tablet) {
    padding: 0; }

  .choose-gallery-single {
    display: inline-block;
    width: 19%;
    margin: 1% 3%;
    font-size: 18px;
    border-bottom: 3px solid $site-blue;
    color: $site-blue;
    height: 40px;
    line-height: 40px;
    //border-radius: 3px
    @include transition(all .2s);

    @include mq(tablet-small) {
      width: 27%;
      font-size: 17px; }

    @include mq(phablet) {
      width: 37%; }

    &.active {
      background: $site-blue;
      color: #fff; }

    &:hover {
      background: $site-blue;
      color: #fff;
      cursor: pointer; } } }

.gallery-mobile-wrap {
  display: none;

  @include mq(tablet-small) {
    display: block; }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 20px; } }



//General settings
body {
  font-family: $font-regular;
  color: #000; }

.content {
  @extend .container;
  width: 1400px;

  @include mq(desktop) {
    width: 1170px; }

  @include mq(1200) {
    width: 970px; }

  @include mq(tablet) {
    width: 750px; }

  @include mq(tablet-small) {
    width: 100%; } }

.tab-hidden {
  display: none; }

main {
  padding-top: 82px;

  @include mq(1200) {
    padding-top: 65px; } }

//Slick slider settings
.slick-slider {
  .slick-list {
    opacity: 0;
    position: absolute; }

  &.slick-initialized {
    background: none;

    .slick-list {
      opacity: 1;
      position: relative;
      transition: opacity 0.2s ease-in; } } }

.home-slides {
  visibility: hidden; }

.slick-initialized {
  visibility: visible; }

.caption-public {
  line-height: 26px;
  background: $site-blue !important; }

.fancybox-lock .fancybox-overlay {
  background: rgba(0,0,0,0.2); }

//Header
header {
  height: $header-height;
  box-shadow: 0 3px 5px 0.1px rgba(0, 0, 0, 0.25);
  background: #FCF2D9;
  position: fixed;
  width: 100%;
  z-index: 999;
  margin-right: 0 !important;

  .header-logo {
    position: absolute;
    top: 7px;
    left: 6%;

    @media only screen and (max-width: 1550px) {
      left: 20px; }

    @media only screen and (max-width: 1360px) {
      left: 10px; }

    @media only screen and (max-width: 1250px) {
      top: 11px; }

    @include mq(1200) {
      left: 50%;
      top: 50%;
      @include transform(translate(-50%,-50%)); }

    @include mq(phone-big) {
      left: 10px;
      @include transform(translate(0,-50%)); }

    img {
      height: 68px;

      @media only screen and (max-width: 1250px) {
        height: 57px; }

      @include mq(1200) {
        height: 55px; } } }

  @include mq(1200) {
    height: 65px; }

  .content {
    @include mq(desktop) {
      padding: 0; }

    @include mq(1200) {
      padding: 0 15px; } }

  #burger {
    display: none;
    @include mq(1200) {
      display: block;
      cursor: pointer;
      width: 130px;
      float: right;

      @include mq(phone) {
        width: auto; }

      i {
        font-size: 40px;
        line-height: 65px;
        margin-right: 15px;
        color: $site-blue;
        vertical-align: middle; }

      span {
        font-size: 24px;
        vertical-align: middle;
        text-transform: uppercase;

        @include mq(phone) {
          display: none; } } } }

  .mobile-menu {
    display: none; }

  .desktop-menu {
    @include clearfix;
    text-align: center;
    @include mq(1200) {
      display: none; }

    li {
      display: inline-block;

      a {
        display: block;
        position: relative;
        line-height: $header-height;
        text-transform: uppercase;
        font: {
          family: $font-medium;
          size: $base-font-size; }
        padding: 0 10px;

        @include mq(desktop) {
          padding: 0 8px; }

        @media only screen and (max-width: 1300px) {
          padding: 0 5px; }

        &.active:before {
          content: '';
          display: block;
          position: absolute;
          width: 90%;
          height: 2px;
          bottom: 25px;
          left: 5%;
          background-color: $new-red;
          visibility: visible;
          @include transform(scaleX(1)); }

        &:hover {
          color: #000; }

        &:before {
          content: '';
          position: absolute;
          width: 90%;
          height: 2px;
          bottom: 25px;
          left: 5%;
          background-color: $new-red;
          visibility: hidden;
          @include transform(scaleX(0));
          @include transition(all .3s ease-in-out 0s); }


        &:hover:before {
          visibility: visible;
          @include transform(scaleX(1)); } }

      &.order-button {
        position: relative;

        a {
          background: $site-red;
          font: {
            family: $font-medium; }
          color: #fff; }

        img {
          @include position(absolute, null null -12px 0);
          width: 100%;
          z-index: 9;
          @include transition(.2s linear); }

        &:hover {
          img {
            bottom: -22px; } } } } } }

.menu-closer {
  display: none; }

.sidr-class-menu-closer {
  @include position(absolute, 25px 25px null null);
  cursor: pointer;

  &:before {
    font-family: 'FontAwesome';
    content: '\f00d';
    font-size: 35px;
    color: $site-blue; } }

.sidr.right {
  right: -100%;
  margin-right: 0 !important; }

.sidr {
  width: 100%;
  background: #fff;
  box-shadow: none; }

.sidr .sidr-inner {
  padding: 80px 0 20px 0;

  li {
    border-bottom: 2px solid $site-blue;
    list-style-type: none;

    &:last-child {
      border-bottom: none; }

    &.sidr-class-order-button {

      img {
        display: none; }

      background-color: $site-red;

      a {
        color: #fff;
        font-family: $font-bold; } }

    a {
      display: block;
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #000;
      text-transform: uppercase;
      font: {
        family: $font-regular;
        size: 20px; } } } }

.sidr-class-cart-icon {
  background: $site-blue;
  position: relative;

  i.sidr-class-fa-shopping-cart:before {
    font-family: 'FontAwesome';
    content: '\f07a';
    color: #fff;
    font-size: 30px;
    font-style: normal; }

  .sidr-class-fa-stack:before {
    position: absolute;
    top: 50%;
    left: 60%;
    @include transform(translate(-50%,-50%));
    content: attr(data-count);
    font-size: 18px;
    border-radius: 50%;
    line-height: 22px;
    color: white;
    background: $new-red;
    text-align: center;
    width: 22px;
    height: 22px;
    font-family: $font-regular; } }

.sidr-class-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 60px; }

.cart-icon {
  a:hover:before {
    visibility: hidden !important; }

  position: absolute;

  .fa-circle {
    color: $site-blue; }

  span {
    font-size: 30px; }

  .fa-stack[data-count]:after {
    position: absolute;
    right: -5px;
    top: 1%;
    content: attr(data-count);
    font-size: 18px;
    border-radius: 50%;
    line-height: 22px;
    color: white;
    background: $new-red;
    text-align: center;
    width: 22px;
    height: 22px;
    font-family: $font-regular; } }


// FAQs
.single-faq {
  border-left: 5px solid $site-red;
  margin-bottom: 70px;

  @include mq(phablet) {
    margin-bottom: 50px;
    border-left-width: 3px; }

  h5 {
    cursor: pointer;
    font: {
      family: $font-boldIT;
      size: 34px; }
    padding: 7px 0 20px 25px;
    color: $site-blue;

    @include mq(tablet-small) {
      font-size: 28px;
      padding: 5px 0 15px 20px; }

    @include mq(phone-big) {
      font-size: 24px;
      padding: 4px 0 13px 16px; } }

  p {

    font-size: 24px;
    color: #5f5f5f;
    line-height: 26px;
    background: #f6f6f6;
    padding: 15px 20px;

    @include mq(tablet-small) {
      font-size: 22px;
      padding: 13px 15px; }

    @include mq(phone-big) {
      font-size: 19px;
      padding: 9px 13px; } } }

.thank-you-wrap {

  h3 {
    position: relative;
    font-family: $font-mp-italic;
    font-size: 48px;
    text-align: center;
    margin-bottom: 60px; }

  .img-holder {
    width: 400px;
    margin: 0 auto 40px;

    img {
      max-width: 100%; } }

  p {
    font-size: 26px;
    text-align: center;
    margin-bottom: 50px; }

  a {
    display: block;
    width: 430px;
    margin: 0 auto 100px;
    height: 50px;
    border: 2px solid #000;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    background: #fff;
    cursor: pointer; } }


//responsive media queries
@mixin mq($width) {
  @if $width == desktop {
    @media only screen and (max-width: 1400px) {
      @content; } }
  @else if $width == laptop {
    @media only screen and (max-width: 1366px) {
      @content; } }
  @else if $width == laptop-small {
    @media only screen and (max-width: 1280px) {
      @content; } }
  @else if $width == 1200 {
    @media only screen and (max-width: 1200px) {
      @content; } }
  @else if $width == tablet-big {
    @media only screen and (max-width: 1024px) {
      @content; } }
  @else if $width == tablet {
    @media only screen and (max-width: 992px) {
      @content; } }
  @else if $width == tablet-small {
    @media only screen and (max-width: 768px) {
      @content; } }
  @else if $width == phablet {
    @media only screen and (max-width: 640px) {
      @content; } }
  @else if $width == phone-big {
    @media only screen and (max-width: 480px) {
      @content; } }
  @else if $width == phone {
    @media only screen and (max-width: 415px) {
      @content; } }
  @else if $width == phone-small {
    @media only screen and (max-width: 360px) {
      @content; } } }

//text
@mixin inner-page-title() {
  font-size: 36px;
  color: #000;
  letter-spacing: 0.1em;
  margin-bottom: 65px;
  text-align: center;
  text-transform: uppercase; }

@mixin inner-page-subtitle() {
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline; }

@mixin basic-text() {
  font: {
    family: $font-medium;
    size: 16px; }
  letter-spacing: 0.1em; }

//spacers
@mixin order-section-setting() {
  position: relative;
  padding-top: 20px;
  padding-bottom: 85px;
  margin-bottom: 15px;
  overflow: hidden;
  background: linear-gradient(to bottom, #f5f5f5 0%,#ffffff 100%);
  //
  //&:after
  //  content: ''
  //  display: block
  //  height: 1px
  //  width: 364px
  //  background: #000
  //  +position(absolute, null null 0 50%)
 }  //  +transform(translate(-50%,0))

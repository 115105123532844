// Shopping cart
.cart-holder {
  margin-bottom: 150px;

  @include mq(tablet) {
    margin-bottom: 100px; } }

.shopping-cart-wrap {
  @include clearfix;
  width: 1000px;
  border: 3px solid $site-blue;
  height: 345px;
  margin: 0 auto;

  @include mq(1200) {
    width: 100%; }

  @include mq(tablet) {
    height: auto; }

  > div {
    float: left;
    height: 100%;
    border-right: 3px solid $site-blue;

    @include mq(tablet) {
      float: none;
      width: 100% !important;
      border-right: none;
      padding: 20px !important;
      border-bottom: 2px solid $site-blue; }

    &:last-child {
      border-right: none; }

    h5 {
      font: {
        family: $font-medium;
        size: 20px; }
      height: 35px;
      line-height: 35px;
      border-bottom: 3px solid $site-blue;
      text-align: center; } }

  .express-service {
    width: 170px;
    text-align: center;
    padding: 60px 0 0;

    p {
      font: {
        size: 24px;
        family: $font-medium; }
      margin-bottom: 20px;

      b {
        font-family: $font-medium;
        font-size: 27px;
        color: $site-red; } }

    > span {
      font-size: 20px;
      display: block;
      margin-bottom: 10px;
      padding: 0 10px; } }

  .quantity-cart {
    width: 120px;
    text-align: center;
    padding: 60px 15px 0;

    @include mq(tablet) {
      @include clearfix; }

    p {
      font: {
        family: $font-medium;
        size: 24px; }
      margin-bottom: 20px;

      @include mq(tablet) {
        float: left;
        margin-right: 15px;
        margin-bottom: 0; }

      @include mq(phone-big) {
        float: none;
        margin: 0 auto 15px; } }

    .select-holder {
      margin-bottom: 115px;

      @include mq(tablet) {
        float: left;
        margin-bottom: 0; }

      @include mq(phone-big) {
        float: none;
        margin: 0 auto 15px; } }

    a {
      display: block;
      font: {
        size: 22px;
        family: $font-light; }
      margin-bottom: 15px;

      @include mq(tablet) {
        float: right;
        margin-left: 15px;
        margin-bottom: 0; }

      @include mq(phone-big) {
        float: none;
        margin: 0 auto 15px;

        &:last-child {
          margin-bottom: 0; } }

      &.remove-order-btn {
        color: $site-red; }

      &.edit-order-btn {
        color: $site-blue; } } }

  .order-info-holder {
    position: relative;
    width: 570px;

    @include mq(1200) {
      width: 510px; }

    .picture-holder {
      @include position(absolute, 50% 50px null null);
      @include transform(translate(0,-50%));
      //border: 1px solid #000
      width: 145px;
      height: 180px;

      img {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        max-width: 100%;
        max-height: 100%; }

      @include mq(phablet) {
        position: relative;
        top: initial;
        right: initial;
        transform: none;
        margin: 0 auto; } }

    .order-info {
      padding: 20px;

      .order-info-line {
        @include clearfix;
        margin-bottom: 20px;

        p {
          float: left;
          margin-right: 5px;
          font-size: 18px; }

        span {
          float: left;
          font-size: 18px;
          font-family: $font-boldIT; } } } }


  .price-cart {
    position: relative;
    width: 134px;

    p {
      @include position(absolute, 50% null null 50%);
      @include transform(translate(-50%,-50%));
      font: {
        family: $font-mp-semiboldIT;
        size: 25px; }
      white-space: nowrap;

      @include mq(tablet) {
        position: relative;
        transform: none;
        top: initial;
        left: initial;
        text-align: center;
        padding-top: 15px; } } } }

.under-cart-holder {
  @include clearfix;
  width: 1000px;
  margin: 0 auto;

  @include mq(1200) {
    width: 100%; }

  .coupon-holder {
    @include clearfix;
    float: left;
    width: 300px;
    padding-top: 5px;

    @include mq(phone-big) {
      width: 100%; }

    p {
      float: left;
      font-size: 19px;
      font-family: $font-mp-italic;
      margin-bottom: 5px;
      width: 100%; }

    input[type="text"] {
      float: left;
      width: 70%;
      margin-right: 3%;
      border: 2px solid $site-blue;
      height: 40px;
      padding: 0 10px;
      font-size: 22px;
      text-align: center; }

    input[type="button"] {
      float: right;
      width: 27%;
      border: 2px solid $site-blue;
      background: $site-blue;
      height: 40px;
      color: #fff;
      font-family: $font-regular;
      font-size: 18px;
      cursor: pointer; } }

  .final-price-holder {
    border: 3px solid $site-blue;
    border-top: none;
    float: right;
    width: 350px;
    padding: 20px 15px;

    @include mq(tablet-small) {
      float: none;
      width: 100%; }

    .final-price-line {
      @include clearfix;
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0; }

      p {
        float: left;
        font: {
          family: $font-regular;
          size: 16px; }
        letter-spacing: 0.1em; }

      span {
        float: right;
        font: {
          family: $font-bold;
          size: 16px; }
        letter-spacing: 0.1em; } } } }

.buttons-holder {
  @include clearfix;
  width: 1000px;
  margin: 0 auto 80px;

  @include mq(1200) {
    width: 100%; }

  a, .add-painting-btn {
    float: left;
    display: block;
    border: none;
    cursor: pointer;
    font: {
      family: $font-medium;
      size: 30px; }
    width: 400px;
    height: 80px;
    border-radius: 3px;
    line-height: 80px;
    text-transform: uppercase;
    text-align: center;
    background: $site-blue;
    color: $new-yellow;
    @include transition(all .1s linear);

    &:hover {
      opacity: 0.8; }

    @include mq(tablet) {
      float: none;
      display: block;
      margin: 0 auto 30px; }

    @include mq(phone-big) {
      width: 100%;
      font-size: 22px; } }

  .continue-btn {
    float: right;
    display: block;
    font: {
      family: $font-medium;
      size: 30px; }
    width: 400px;
    height: 80px;
    border: none;
    line-height: 80px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background: $new-red;
    color: $new-yellow;
    @include transition(all .1s linear);

    &:hover {
      opacity: 0.8; }

    @include mq(tablet) {
      float: none;
      display: block;
      margin: 0 auto 30px; }

    @include mq(phone-big) {
      width: 100%;
      font-size: 22px; } } }

// Billing / Shipping part
.billing-holder {
  @include clearfix;
  width: 1030px;
  margin: 0 auto 100px;

  @include mq(1200) {
    width: 100%;
    margin-bottom: 70px; }

  .billing-half {
    @extend .col-md-6;
    background: #F2F1EF;
    padding-top: 20px;
    padding-bottom: 20px;

    @include mq(tablet) {
      margin-bottom: 30px; }

    .billing-content {
      margin-bottom: 40px;

      input[type="checkbox"] {
        opacity: 1 !important; }

      h4 {
        font-size: 32px;
        margin-bottom: 35px; }

      .checkbox-wrap {
        margin-bottom: 30px;

        label {
          font-size: 22px; } }

      .input-wrap {
        margin-bottom: 22px;

        label {
          font-size: 22px;
          padding-left: 8px;
          display: block;
          margin-bottom: 7px;
          letter-spacing: 0.05em;

          span {
            color: #dc3c2f;
            font-family: $font-mp-semiboldIT;
            font-size: 26px;
            line-height: 0;
            vertical-align: middle; } }

        input {
          width: 100%;
          height: 45px;
          border: 2px solid $site-blue;
          padding: 0 20px;
          font-size: 20px; }

        .select2 {
          width: 100% !important;
          height: 45px !important; }

        .select2-container--default .select2-selection--single {
          border: 2px solid $site-blue !important;
          height: 45px !important; }

        .select2-container .select2-selection--single .select2-selection__rendered {
          height: 45px !important;
          line-height: 45px !important; }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
          height: 45px; } } } } }

.under-billing-text {
  color: $site-red;
  font-family: $font-mp-semiboldIT;
  font-size: 18px;
  padding-left: 15px;
  float: left;
  margin-top: 10px;
  width: 100%; }



.paying-method-holder {
  text-align: center;
  margin-bottom: 100px;

  .single-paying-method {
    display: inline-block;
    margin: 0 120px 20px;
    cursor: pointer;

    @include mq(tablet-small) {
      margin: 0 0 20px;
      display: block; }

    input, label {
      display: inline-block;
      vertical-align: middle; }

    img {
      max-height: 60px;

      @include mq(phone-big) {
        height: 35px; } } }


  p {
    color: #000;
    font-family: $font-boldIT;
    font-size: 19px;
    line-height: 1.3;

    br {
      @include mq(phone-big) {
        display: none; } } } }

/* RADIO BUTTONS */

.paying-method-holder {

  [type="radio"] {
    visibility: hidden;
    &:not(:checked) + label, &:checked + label {
      position: relative;
      padding-left: 20px;
      margin-left: -20px;
      cursor: pointer; }
    &:not(:checked) + label:before, &:checked + label:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 18px;
      width: 22px;
      height: 22px;
      border: 2px solid #cacaca;
      background: #fff;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;

      @include mq(phone-big) {
        top: 6px; } }

    &:not(:checked) + label:after, &:checked + label:after {
      content: '';
      position: absolute;
      top: 18px;
      left: -20px;
      width: 26px;
      height: 26px;
      background: $site-blue;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      transition: all .2s;

      @include mq(phone-big) {
        top: 6px; } }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0); }
    &:checked + label:after {
      opacity: 1;
      transform: scale(1); } }

  /* Radio button style */

  /* Checked style */

  /* checked mark aspect changes */

  /* Label hover style */

  label:hover:before {
    border: 2px solid $site-blue !important; } }

.samples {
  margin: 0 0 20px 0; }

.samples-current {
  height: 380px;
  background: url("images/loading.gif") no-repeat center center;
  text-align: center;
  overflow: hidden;
  margin-bottom: 10px; }

a.timer {
  width: 24px;
  height: 24px;
  margin: 0 auto 10px auto;
  background: url("images/playpause.png") no-repeat bottom left;
  display: block;
  &.stopped {
    background-position: 0 0; } }

.samples-scroll {
  height: 110px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ul {
    width: 9999px; }
  li {
    height: 86px;
    /* (!) 80 + padding + border */
    border: 1px solid #ccc;
    margin-right: 5px;
    padding: 2px;
    float: left;
    &:last-child {
      margin: 0; }
    &:hover, &.current {
      border-color: #666; }
    &:hover img, &.current img {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity = 50);
      -moz-opacity: 0.5;
      -khtml-opacity: 0.5;
      opacity: 0.5; } }
  a {
    background-color: #000;
    display: block; } }

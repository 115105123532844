// Hero slider
.home-slider-holder {
  margin-bottom: 50px;
  position: relative;

  @include mq(phablet) {
    margin-bottom: 30px; }

  //&:after
  //  content: ''
  //  display: block
  //  width: 100%
  //  height: 1px
  //  +position(absolute, null null 12px 0)
  //  background: #000

  .slick-list {
    margin-bottom: 50px;

    @include mq(phablet) {
      margin-bottom: 30px; } }

  .slick-prev, .slick-next {
    width: 55px;
    height: 55px;

    @include mq(1200) {
      display: none !important; } }

  .slick-next {
    right: 30px;
    z-index: 99; }

  .slick-prev {
    left: 30px;
    z-index: 99; }

  .slick-prev::before, .slick-next::before {
    font-size: 55px;
    color: $site-blue; }

  .slick-dots {
    z-index: 9;
    bottom: 15px;

    @include mq(1200) {
      display: none !important; }


    li {

      button {
        border-radius: 50%;
        padding: 0;
        background: $new-red;


        &:before {

          content: ''; } }

      &.slick-active {

        button {
          background: $site-blue; } } } } }

// Home content
.home-content {
  h3 {
    position: relative;
    font: {
      family: $font-mp-italic;
      size: $title-normal-size; }
    text-align: center;
    margin-bottom: 120px;

    @include mq(tablet) {
      margin-bottom: 80px; }

    @include mq(phablet) {
      font-size: 32px;
      margin-bottom: 50px; }

    @include mq(phone) {
      font-size: 27px; }

    &:after {
      content: '';
      display: block;
      height: 1px;
      background: #000;
      width: 500px;
      @include position(absolute, null null -30px 50%);
      @include transform(translate(-50%,0));

      @include mq(phablet) {
        width: 90%; } } } }

.home-boxes {
  @include clearfix;

  .home-box {
    @include clearfix;
    @extend .col-md-6;
    padding: 0;
    margin-bottom: 100px;

    @include mq(tablet) {
      margin-bottom: 40px; }

    @include mq(phablet) {
      margin-bottom: 30px; }

    .img-holder {
      float: left;
      width: 230px;
      height: 230px;
      position: relative;
      @include mq(desktop) {
        width: 35%;
        height: 190px; }

      @include mq(phablet) {
        width: 29%;
        margin-right: 1%;
        height: 150px; }

      img {
        display: block;
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        @include mq(desktop) {
          width: 90%; }

        @include mq(phablet) {
          width: 100%; } } }


    .home-box-text {

      padding-top: 35px;

      @include mq(desktop) {
        width: 65%;
        float: left; }

      @include mq(phablet) {
        width: 70%; }

      h4 {
        text-transform: uppercase;
        font: {
          family: $font-medium;
          size: 28px; }
        margin-bottom: 25px;
        letter-spacing: 0.025em;

        @include mq(tablet) {
          font-size: 25px; }

        @include mq(phablet) {
          font-size: 23px; } }

      p {
        font: {
          size: 20px; }
        letter-spacing: 0.065em;

        @include mq(tablet) {
          font-size: 18px; }

        @include mq(phablet) {
          font-size: 17px; }

        br {
          @include mq(desktop);
          display: none; } } } } }

.home-buttons {
  @include clearfix;
  margin-bottom: 75px;

  @include mq(phablet) {
    margin-bottom: 50px; }

  .button-holder {
    @extend .col-md-6;

    @include mq(tablet);
    margin-bottom: 30px;

    a {
      display: block;
      width: 300px;
      height: 80px;
      color: $new-yellow;
      border: none;
      border-radius: 3px;
      outline: none;
      text-align: center;
      line-height: 80px;
      font: {
        family: $font-medium;
        size: 34px; }
      text-transform: uppercase;
      margin: 0 auto;
      background: $new-red;
      cursor: pointer;
      @include transition(.2s ease);

      &:hover {
        opacity: 0.8; }

      @include mq(phablet) {
        width: 200px;
        height: 60px;
        font-size: 25px;
        line-height: 60px; } } } }

//Reviews
.review-holder {
  padding: 0 100px;
  margin-bottom: 100px;

  @include mq(tablet-big) {
    padding: 0; }

  @include mq(phone-big) {
    margin-bottom: 60px; }

  h4 {
    font-size: 28px;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 60px; } }

.review-text {
  font: {
    size: 24px; }
  text-align: center;
  line-height: 1.2;
  color: $site-blue;
  margin-bottom: 120px;

  @include mq(tablet) {
    font-size: 22px;
    margin-bottom: 60px; } }

.photo-review-holder {
  margin-bottom: 90px;

  @include mq(tablet) {
    margin-bottom: 60px; } }

.video-review {
  @include clearfix;
  margin-bottom: 90px;

  @include mq(tablet) {
    margin-bottom: 60px; }

  .video-holder {
    @extend .col-xs-6;
    padding: 0 10px;
    margin-bottom: 20px;

    @include mq(phablet) {
      width: 100%; } } }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

.single-text-review {
  font: {
    size: 25px; }
  letter-spacing: 0.05em;
  line-height: 1.15;
  margin-bottom: 40px;

  @include mq(tablet) {
    font-size: 22px; }

  @include mq(phone-big) {
    font-size: 20px; }

  .img-holder {
    float: left;
    margin-right: 30px;
    margin-bottom: 40px;

    img {
      max-height: 180px; } }



  span {
    font-family: $font-bold;
    margin-bottom: 40px;
    display: block;

    &:before {
      content: '- '; } } }


/* hide checkbox and radio inputs from modern browsers */
body:not(#foo) input[type='checkbox'] {
    opacity: 0;
}

body:not(#foo) input[type='radio'] {
    opacity: 0;
}

/* set default styles for the checkboxes and radio buttons */
body:not(#foo) .checkbox,.radiobtn {
    width: 61px;
    height: 61px;
    display: inline-block;
    margin: 0 10% 0 auto;
    cursor: pointer;
    -webkit-filter: drop-shadow(11px 10px 14px rgba(0,0,0,0.25));
    -moz-filter: drop-shadow(11px 10px 14px rgba(0,0,0,0.25));
    filter: drop-shadow(11px 10px 14px rgba(0,0,0,0.25));
    position: relative;
    border: 1px solid #294076;
}


.blue label span.checkbox {
    background: url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/checkbox.png) 0px 0px;
}


.blue input[type='checkbox']:checked + label span.checkbox {
    background: url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/checkbox.png) 0px -110px;
}

.blue label span.radiobtn {
    background: url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/radiobtn.png) 0px 0px;
}


.blue input[type='radio']:checked + label span.radiobtn {
    background: url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/radiobtn.png) 0px -110px;
}


.green label span.checkbox {
    background: #ecf0f1 url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/checkbox_transparent.png);
}

.green input[type='checkbox']:checked + label span.checkbox {
    background: #ecf0f1 url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/checkbox_transparent.png);
}

.green label span.radiobtn {
    background: #ecf0f1 url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/radio_transparent.png);
    border-radius: 100px;
}

.green input[type='radio']:checked + label span.radiobtn {
    background: #ecf0f1 url(http://www.8bit-code.com/demos/styling-checkboxes-and-radio-buttons-with-css-embed/img/radio_transparent.png);
}


.dark-blue input[type='checkbox'] + label span.checkbox {
    background: #ecf0f1;
}

.dark-blue input[type='checkbox']:checked + label span.checkbox:after {
    content: "";
    position: absolute;
    top: 23px;
    left: 14px;
    width: 9px;
    height: 26px;

    background: #294076;
    z-index: 1;
    -webkit-transform: rotate(-44deg);
    -moz-transform: rotate(-44deg);
    transform: rotate(-44deg);
    border-radius: 2px;
}

.dark-blue input[type='checkbox']:checked + label span.checkbox:before {
    content: "";
    position: absolute;
    top: 13px;
    right: 19px;
    width: 9px;
    height: 38px;
    background: #294076;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 2px;
}

.dark-blue input[type='radio'] + label span.radiobtn {
    background: #ecf0f1;
    border-radius: 100px;
}


.dark-blue input[type='radio']:checked + label span.radiobtn:after {
    content: "";
    position: absolute;
    top: 37px;
    right: 35px;
    width: 30px;
    height: 30px;
    background: #95a5a6;
    z-index: 1;
    border-radius: 30px;
}

.orange label span.checkbox {
    background: #ecf0f1;
    padding: 18px 13px 13px 13px;
    width: 74px !important;
    height: 69px !important;
}

.orange label span.checkbox svg {
    display: none;
}

.orange input[type='checkbox']:checked + label span.checkbox svg {
    display: block;
}

.orange input[type='radio'] + label span.radiobtn {
    background: #ecf0f1;
    border-radius: 100px;
}

.orange label span.radiobtn svg {
    display: none;
}

.orange input[type='radio']:checked + label span.radiobtn svg {
    display: block;
}
// Media comaprison
.comparison-holder {
  margin-bottom: 100px;
  @include mq(tablet) {
    margin-bottom: 80px; }

  @include mq(phone) {
    margin-bottom: 60px; }

  .comparison-single {
    margin-bottom: 50px;
    padding: 40px 0;
    background: #F5F5F5;

    @include mq(tablet) {
      margin-bottom: 60px; }

    @include mq(phone) {
      margin-bottom: 45px; }

    h5 {
      font-size: 24px;
      letter-spacing: 0.1em;
      margin-bottom: 30px;
      text-align: center;
      @include mq(tablet) {
        font-size: 23px;
        margin-bottom: 30px; } }

    .comparison-images {
      text-align: center;
      margin-bottom: 60px;

      @include mq(tablet) {
        margin: 0; }

      .comparison-img {
        display: inline-block;
        margin: 0 30px;

        @include mq(1200) {
          margin: 0 10px; }

        img {
          max-width: 100%; }

        @include mq(tablet) {
          margin: 0 0 25px; } } }

    .comparison-video-holder {
      width: 460px;
      margin: 0 auto;

      @include mq(phone-big) {
        width: 97%; } } } }

// select2 override
.select2-results {
  text-align: center; }

.select2-dropdown {
  border-color: #000; }

.select2-search--dropdown {
  display: none; }

.select2-container--open .select2-dropdown--below {
  border-radius: 0; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $site-blue; }

.select2-container--default .select2-selection--single {
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  height: 34px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000; }

.select2-container .select2-selection--single .select2-selection__rendered {
  height: 34px;
  line-height: 34px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #000 transparent transparent transparent; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #000 transparent; }

.select2 {
  width: 200px !important;
  display: block;
  height: 34px;
  margin: 0 auto;
  text-align: center; }

.express-service .select2 {
  width: 100px !important; }

.quantity-cart .select2 {
  width: 80px !important; }
